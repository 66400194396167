import React from 'react';
import { graphql } from 'gatsby';

import { buildGetServerData } from '../../middlewares/common';
import { withLanguageBasedRedirect } from '../../middlewares/language-redirect';
import { ConsentManager } from '../../services/segment/ConsentManager';
import { useInitPage } from '../processors/hooks';
import ProcessPageData from '../processors/pageData';
import BlocksManager from '../widgets/Blocks/BlocksManager';
import Footer from '../widgets/Footer/Footer';
import Layout from '../widgets/Layout';
import NavHeader from '../widgets/NavHeader/NavHeader';
import { PageHead } from '../widgets/PageHead';
import { PageContext } from './context';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DynamicPage = (context: any) => {
  const content = ProcessPageData(context);

  const { templates, blocks, locale } = content;

  useInitPage();

  return (
    <PageContext.Provider value={content}>
      <ConsentManager
        locale={locale}
        writeKey={process.env.GATSBY_SEGMENT_WRITE_KEY}
      />
      <>
        <NavHeader />
        <Layout data={templates}>
          <BlocksManager locale={locale} data={blocks} />
        </Layout>
        <Footer />
      </>
    </PageContext.Provider>
  );
};

export const Head = PageHead;

export const query = graphql`
  query PageQuery($locale: String) {
    allCmsLocale {
      nodes {
        code
        name
        isDefault
        id
      }
    }
    allSitePage {
      nodes {
        path
        pageContext
      }
    }
    allStrapiGlobal(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          siteName
          locale
          id
          favicon {
            alternativeText
            localFile {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData(
                  width: 50
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          defaultPreviewImage {
            alternativeText
            localFile {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          footer {
            Copyright
            languageLabel
            socialNetworksLabel
            app_stores {
              Name
              Url
              locale
              Badge {
                alternativeText
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      width: 250
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            social_networks {
              AccountID
              Active
              Name
              ShowOnFooter
              Url
              Icon {
                alternativeText
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      width: 30
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              IconDark {
                alternativeText
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      width: 30
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
          darkLogo {
            alternativeText
            localFile {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData(
                  width: 150
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          lightLogo {
            alternativeText
            localFile {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData(
                  width: 150
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    allMainNav(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        type
        locale
        id
        external
        menuAttached
        beta
        order
        path
        strapi_id
        uiRouterKey
      }
    }
    allFooterNav(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        type
        locale
        id
        external
        menuAttached
        order
        path
        strapi_id
        uiRouterKey
        subItems {
          external
          id
          menuAttached
          order
          path
          title
          type
          uiRouterKey
          related {
            FooterTemplate
            PageTemplate
            Slug
            Title
            id
          }
        }
      }
    }
    allStrapiDefaultSeo(filter: { locale: { eq: $locale } }) {
      nodes {
        locale
        SEO {
          author
          canonicalURL
          keywords
          metaDescription
          metaRobots
          metaTitle
          metaSocial {
            id
            content
            propertyOrName
            socialNetwork
          }
          metaViewport
          metaImage {
            localFile {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;

export const getServerData = buildGetServerData(
  [withLanguageBasedRedirect],
  () => {
    return {
      status: 200,
      props: {},
    };
  },
);

export default DynamicPage;
